import equal from 'fast-deep-equal'
import difference from 'lodash/difference'
import get from 'lodash/get'
import mapValues from 'lodash/mapValues'
import set from 'lodash/set'
import unset from 'lodash/unset'
import { IncompleteInformation } from '../IncompleteInformation'
import { Material, MaterialItem } from './items'
import { MaterialGame } from './MaterialGame'
import { MaterialRules } from './MaterialRules'
import {
  isMoveItem,
  isShuffle,
  ItemMoveType,
  MaterialMove,
  MaterialMoveRandomized,
  MaterialMoveView,
  MoveItem,
  MoveKind,
  Shuffle,
  ShuffleRandomized
} from './moves'
import { HidingSecretsStrategy } from './SecretMaterialRules'

export abstract class HiddenMaterialRules<P extends number = number, M extends number = number, L extends number = number>
  extends MaterialRules<P, M, L>
  implements IncompleteInformation<MaterialGame<P, M, L>, MaterialMove<P, M, L>, MaterialMove<P, M, L>> {

  constructor(game: MaterialGame<P, M, L>, private readonly client?: { player?: P }) {
    super(game)
  }

  abstract readonly hidingStrategies: Partial<Record<M, Partial<Record<L, HidingStrategy<P, L>>>>>

  override material(type: M): Material<P, M, L> {
    return new Material(type, Array.from((this.game.items[type] ?? []).entries()).filter(entry => entry[1].quantity !== 0),
      move => {
        if (isMoveItem(move) && this.game.players.some(player => this.moveItemWillRevealSomething(move, player))) {
          move.reveal = {}
        }
      }
    )
  }

  itemsCanMerge(type: M): boolean {
    return !this.hidingStrategies[type]
  }

  isUnpredictableMove(move: MaterialMove<P, M, L>, player: P): boolean {
    if (isMoveItem(move)) {
      return this.moveItemWillRevealSomething(move, player)
    } else if (isShuffle(move)) {
      return this.canSeeShuffleResult(move, player)
    } else {
      return super.isUnpredictableMove(move, player)
    }
  }

  protected moveBlocksUndo(move: MaterialMove<P, M, L>): boolean {
    return super.moveBlocksUndo(move) || this.moveRevealsSomething(move)
  }

  protected moveRevealsSomething(move: MaterialMove<P, M, L>): boolean {
    return isMoveItem(move) && !!move.reveal
  }

  getView(player?: P): any {
    return {
      ...this.game,
      items: mapValues(this.game.items, (items, stringType) => {
        const itemsType = parseInt(stringType) as M
        const hidingStrategies = this.hidingStrategies[itemsType]
        if (!hidingStrategies || !items) return items
        return items.map(item => this.hideItem(itemsType, item, player))
      })
    }
  }

  private hideItem(type: M, item: MaterialItem<P, L>, player?: P): MaterialItem<P, L> {
    const paths = this.getItemHiddenPaths(type, item, player)
    if (!paths.length) return item
    const hiddenItem = JSON.parse(JSON.stringify(item))
    for (const path of paths) {
      unset(hiddenItem, path)
    }
    return hiddenItem
  }


  private getItemHiddenPaths(type: M, item: MaterialItem<P, L>, player?: P): string[] {
    const hidingStrategy = this.hidingStrategies[type]?.[item.location.type]
    return hidingStrategy ? (hidingStrategy as HidingSecretsStrategy<P, L>)(item, player) : []
  }

  getMoveView(move: MaterialMoveRandomized<P, M, L>, player?: P): MaterialMove<P, M, L> {
    if (move.kind === MoveKind.ItemMove && move.itemType in this.hidingStrategies) {
      switch (move.type) {
        case ItemMoveType.Move:
          return this.getMoveItemView(move, player)
        case ItemMoveType.Create:
          return { ...move, item: this.hideItem(move.itemType, move.item, player) }
        case ItemMoveType.Shuffle:
          return this.getShuffleItemsView(move, player)
      }
    }
    return move
  }

  private getMoveItemView(move: MoveItem<P, M, L>, player?: P): MoveItem<P, M, L> {
    const revealedPaths = this.getMoveItemRevealedPath(move, player)
    if (!revealedPaths.length) return move
    const item = this.material(move.itemType).getItem(move.itemIndex)!
    const moveView = { ...move, reveal: {} }
    for (const path of revealedPaths) {
      set(moveView.reveal, path, get(item, path))
    }
    return moveView
  }

  private getMoveItemRevealedPath(move: MoveItem<P, M, L>, player?: P): string[] {
    const item = this.material(move.itemType).getItem(move.itemIndex)!
    const hiddenPathsBefore = this.getItemHiddenPaths(move.itemType, item, player)
    const hiddenPathsAfter = this.getItemHiddenPaths(move.itemType, this.mutator(move.itemType).getItemAfterMove(move), player)
    return difference(hiddenPathsBefore, hiddenPathsAfter)
  }

  private moveItemWillRevealSomething(move: MoveItem<P, M, L>, player?: P): boolean {
    return this.getMoveItemRevealedPath(move, player).length > 0
  }

  private getShuffleItemsView(move: ShuffleRandomized<M>, player?: P): Shuffle<M> {
    if (this.canSeeShuffleResult(move, player)) return move
    const { newIndexes, ...moveView } = move
    return moveView
  }

  private canSeeShuffleResult(move: Shuffle<M>, player?: P): boolean {
    if (!this.hidingStrategies[move.itemType]) return true
    const material = this.material(move.itemType)
    const hiddenPaths = this.getItemHiddenPaths(move.itemType, material.getItem(move.indexes[0])!, player)
    if (process.env.NODE_ENV === 'development' && move.indexes.some(index =>
      !equal(hiddenPaths, this.getItemHiddenPaths(move.itemType, material.getItem(index)!, player))
    )) {
      throw new RangeError(`You cannot shuffle items with different hiding strategies: ${
        JSON.stringify(move.indexes.map(index => this.getItemHiddenPaths(move.itemType, material.getItem(index)!, player)))
      }`)
    }
    // TODO: if we shuffle a hand of items partially hidden, we should send the partially visible information to the client.
    // Example: It's a Wonderful World with the Extension: the back face of the player's hand are different
    // => when the hand is shuffled we should see where the expansion cards land.
    return !hiddenPaths.length
  }

  play(move: MaterialMoveRandomized<P, M, L> | MaterialMoveView<P, M, L>): MaterialMove<P, M, L>[] {
    const result = super.play(move)
    if (this.client && isMoveItem(move) && this.hidingStrategies[move.itemType]) {
      const item = this.material(move.itemType).getItem(move.itemIndex)
      if (item) {
        this.game.items[move.itemType]![move.itemIndex] = this.hideItem(move.itemType, item, this.client.player)
      }
    }
    return result
  }
}

export type HidingStrategy<P extends number = number, L extends number = number> = (item: MaterialItem<P, L>) => string[]

export const hideItemId: HidingStrategy = () => ['id']
export const hideFront: HidingStrategy = () => ['id.front']
