import { CustomMove, MaterialMove, SimultaneousRule } from '@gamepark/rules-api'
import { CustomMoveType } from '../material/CustomMoveType'
import { LocationType } from '../material/LocationType'
import { MaterialType } from '../material/MaterialType'
import { Memory } from './Memory'
import { RuleId } from './RuleId'

export const START_HAND = 7

export class MulliganRule extends SimultaneousRule {
  onRuleStart() {
    return this.game.players.flatMap(player =>
      this.material(MaterialType.FactionCard).location(LocationType.PlayerDeck).player(player)
        .deck().deal({ type: LocationType.PlayerHand, player }, START_HAND)
    )
  }

  getLegalMoves(player: number) {

    if (!this.isTurnToPlay(player)) {
      return []
    }

    const cardsInHand = this.material(MaterialType.FactionCard).location(LocationType.PlayerHand).player(player)


    const moves: MaterialMove[] = cardsInHand.moveItems({ type: LocationType.PlayerDeck, player })

    if (cardsInHand.length < START_HAND) {
      moves.push(this.rules().customMove(CustomMoveType.Mulligan, { player }))
    } else {
      moves.push(this.rules().endPlayerTurn(player))
    }

    return moves
  }

  onCustomMove(move: CustomMove) {
    if (move.type !== CustomMoveType.Mulligan) return []

    const player = move.data.player

    const cardsInDeck = this
      .material(MaterialType.FactionCard)
      .location(LocationType.PlayerDeck)
      .player(player)

    const moves: MaterialMove[] = [
      this.rules().endPlayerTurn(player),
      cardsInDeck.shuffle()
    ]

    const cardsInHand = this
      .material(MaterialType.FactionCard)
      .location(LocationType.PlayerHand)
      .player(player)
      .length

    moves.push(
      ...cardsInDeck
        .sort(card => -card.location.x!)
        .limit(START_HAND - cardsInHand)
        .moveItems({ type: LocationType.PlayerHand, player })
    )

    return moves
  }

  getMovesAfterPlayersDone() {
    return [this.rules().startPlayerTurn(RuleId.PlacementRule, this.remind(Memory.StartPlayer))]
  }
}
