export enum Family {
  Blacksmith = 1,
  Troll,
  SixthLegion,
  Artillery,
  Yhdorian,
  Sentinel,
  ChildrenOfDawn,
  IceGuard,
  Nihilist
}
