export enum CustomMoveType {
  ChooseFaction = 1,
  ChoosePlayer,
  Mulligan,
  Attack,
  SolveAttack,
  PerformAction,
  ChooseCard,
  Pass
}
