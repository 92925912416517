import { CloseRulesDisplay, DisplayRules } from './DisplayRules'
import { DropItem } from './DropItem'
import { SetTutorialStep } from './SetTutorialStep'
import { CloseTutorialPopup } from './CloseTutorialPopup'

export type LocalMove<Player extends number = number, MaterialType extends number = number, LocationType extends number = number>
  = DisplayRules<Player, MaterialType, LocationType>
  | CloseRulesDisplay
  | DropItem<MaterialType>
  | SetTutorialStep
  | CloseTutorialPopup

export enum LocalMoveType {
  DisplayRules, CloseRulesDisplay, DropItem, SetTutorialStep, CloseTutorialPopup
}