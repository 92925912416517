import { LocalMoveType } from './LocalMove'
import { MoveKind } from '../MoveKind'
import { MaterialItem } from '../../items'
import { Location } from '../../location'

export type RulesDisplay<P extends number = number, M extends number = number, L extends number = number>
  = MaterialRulesDisplay<P, M, L>
  | LocationRulesDisplay<P, L>

export enum RulesDisplayType {
  Material = 1, Location
}

export type MaterialRulesDisplay<P extends number = number, M extends number = number, L extends number = number> = {
  type: typeof RulesDisplayType.Material
  itemType: M
  itemIndex?: number
  item: Partial<MaterialItem<P, L>>
}

export type LocationRulesDisplay<P extends number = number, L extends number = number> = {
  type: typeof RulesDisplayType.Location
  location: Location<P, L>
}

export type DisplayRules<P extends number = number, M extends number = number, L extends number = number> = {
  kind: MoveKind.LocalMove
  type: typeof LocalMoveType.DisplayRules
  rulesDisplay: RulesDisplay<P, M, L>
}

export const displayRules = <P extends number = number, M extends number = number, L extends number = number>
(rulesDisplay: RulesDisplay<P, M, L>): DisplayRules<P, M, L> =>
  ({ kind: MoveKind.LocalMove, type: LocalMoveType.DisplayRules, rulesDisplay })

export const displayMaterialRules = <P extends number = number, M extends number = number, L extends number = number>
(itemType: M, item: Partial<MaterialItem<P, L>> = {}, itemIndex?: number): DisplayRules<P, M, L> =>
  displayRules({ type: RulesDisplayType.Material, itemType, itemIndex, item })

export const displayLocationRules = <P extends number = number, M extends number = number, L extends number = number>
(location: Location<P, L>): DisplayRules<P, M, L> =>
  displayRules({ type: RulesDisplayType.Location, location })

export type CloseRulesDisplay = {
  kind: MoveKind.LocalMove
  type: typeof LocalMoveType.CloseRulesDisplay
}

export const closeRulesDisplay: CloseRulesDisplay = { kind: MoveKind.LocalMove, type: LocalMoveType.CloseRulesDisplay }
